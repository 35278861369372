'use strict';

var core = require('core/wishlist/detail');

var productBase = require('core/product/base');
var wishlistHelpers = require('core/wishlist/components/helpers');
var zonos = require('../zonos');

var cardProductEventClickAddCart = function(e) {
    e.preventDefault();
    $.spinner().start();
    var $this = $(this);
    var $cardProduct = $this.parents('.wishlist-card-product');
    var addToCartUrl = $this.data().url;
    var pid = $cardProduct.data('pid');
    var quantity = $cardProduct.find('.wishlist-card-product-quantity').val();
    var options = JSON.stringify($this.data().options);
    $.ajax({
        url: addToCartUrl,
        method: 'POST',
        data: {
            pid,
            quantity,
            options
        },
        dataType: 'json',
        success: function (data) {
            productBase.methods.handlePostCartAdd(data);
            $('body').trigger('product:afterAddToCart', data);
            productBase.methods.miniCartReportingUrl(data.reportingURL, data.error);
            $.spinner().stop();

            // LBH custom - launch removal confirmation modal after adding to cart
            setTimeout(() => {
                $('.add-to-basket-alert').remove();
                $cardProduct.find('.wishlist-card-product-remove').trigger('click');
            }, 2000);
        },
        error: function () {
            $.spinner().stop();
        }
    });
};

var deleteProductModalEventClickDelete = function(e) {
    e.preventDefault();
    var $modal = $('#wishlistModalDeleteProduct');
    var detailPageSize = wishlistHelpers.getUrlParam('sz') ? Number(wishlistHelpers.getUrlParam('sz')) : null;
    $.spinner().start();
    $.ajax({
        url: $modal.data().url,
        method: 'POST',
        dataType: 'json',
        data: {
            listId: $modal.data().listId,
            listItemId: $modal.data().listItemId,
            detailPageSize: detailPageSize
        },
        success: function(data) {
            var $productsHtml = $(data.renderedTemplate);
            if (!data.error && $productsHtml.length) {
                $('.wishlist-details').trigger('updateProducts', $productsHtml);
            } else {
                toastHelpers.methods.show('danger', data.message, true);
                $.spinner().stop();
            }
            zonos.updateZonosPricing();
            $modal.modal('hide');
        },
        error: function() {
            $modal.modal('hide');
            $.spinner().stop();
        }
    });
}

core.methods.deleteProductModalEventClickDelete = deleteProductModalEventClickDelete;
core.methods.cardProductEventClickAddCart = cardProductEventClickAddCart;

module.exports = core;
