'use strict';

var core = require('core/components/modal');

function onModalLoaded() {
    $('body').on('modal:loaded', function(event, modal) {
        var $modal = $(modal);

        // Check for modal-scrollbody helper class and adjust body max-height if found
        // LBH update - remove .choose-bonus-product-dialog from this conditional
        if ($modal.find('.modal-scrollbody').length || $modal.find('.quick-view-dialog').length) {
            core.setModalBodyMaxHeight($modal);
        }

        // Reinitialize mobile modal sliders to prevent tiny slider error on drag
        var $modalSlider = $modal.find('.slider-container .slider');
        if ($modalSlider.length && window.isMobile()) {
            $modalSlider.trigger('slider:destroy');
            abSlider.initializeSliders($modal);
        }
    });
}

core.onModalLoaded = onModalLoaded;
core.init = function() {
    core.onShownBsModal();
    core.onModalLoaded();
}

module.exports = core;
