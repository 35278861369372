'use strict';

var core = require('core/product/detail');

function updateAttributes() {
    var Extend = window.Extend || undefined;
    $('body').on('product:statusUpdate', function (e, data) {
        var $productContainer = $('.product-detail[data-pid="' + data.id + '"]');

        $productContainer.find('.main-content-group .product-attributes')
            .empty()
            .html(data.attributesHtml);

        $('body').trigger('tooltip:init');
        if ($('button.add-to-cart-global').length) {
            module.exports.updateAddAllToCart();
        }
        if ($('#extend-offer').length) {
            const category = $('#extend-offer').data('category');
            Extend.setActiveProduct('#extend-offer', {
                referenceId: data.id.replace(/_/g, ' '),
                category: category
            });
        }
    });
}

function extendInit () {
    var Extend = window.Extend || undefined;
    var EXT_STORE_ID = window.EXT_STORE_ID || undefined;
    var EXT_ENVIRONMENT = window.EXT_ENVIRONMENT || undefined;
    if ($('#extend-offer').length && Extend != null) {
        var pid = $('.product-id.p.d-none').text().replace(/_/g, ' ');
        Extend.config({ storeId: EXT_STORE_ID, environment: EXT_ENVIRONMENT });
        setTimeout(function() {
            if (!Extend.buttons.instance('#extend-offer')) {
                Extend.buttons.render('#extend-offer', {
                    referenceId: pid
                });
            }
        }, 2000);
    };
}

core.extendInit = extendInit;
core.dropdownMenu = updateAttributes;

module.exports = core;
