'use strict';

function init () {
    $('.collection-module-table').each( function(i, element) {
        var cols = $(element).find('.image-row').data('columns');
        $(element).addClass(`c-${cols}`)
    })
}

module.exports = init;
