'use strict';

var abSlider = require('core/components/slider');
var tinySlider = require('tiny-slider/src/tiny-slider');
var imagesLoaded = require('imagesloaded');
var SiteConstants = require('constants/SiteConstants');
var mediumBreakpoint = SiteConstants.BreakpointSizes.md;
var largeBreakpoint = SiteConstants.BreakpointSizes.lg;
var extraLargeBreakpoint = SiteConstants.BreakpointSizes.xl;

// Build out slide html for replacing images on variant selection
abSlider.getUpdatedSlideItems = function(images, assets, videos, isThumbnail) {
    var slideItems = [];

    images['large'].forEach(image => {
        var $slideElement = $('<div></div>').attr('class', 'slide');
        var $imageElement = $('<img/>');

        if (!isThumbnail) {
            $slideElement.attr('data-zoom-image', images['hi-res'].length ? images['hi-res'][image.index].url : images['large'][image.index].url);
        }

        $imageElement.attr({
            'src': image.url,
            'alt': image.alt + ' image number ' + (parseInt(image.index) + 1).toFixed(0),
            'class': 'd-block img-fluid',
            'itemprop': 'image'
        });

        if (images['large-no-image']?.url?.length > 0) {
            $imageElement.attr('onerror', 'this.onerror=null;this.src=\'' + images['large-no-image'].url + '\';');
        }

        $slideElement.append($imageElement);
        slideItems.push($slideElement[0]);
    });

    abSlider.getPDPGalleryAssetSlides(slideItems, assets, isThumbnail);

    // get vimeo videos and insert after first slide
    var videoSlides = abSlider.getVideoSlides(videos, isThumbnail);
    if (videoSlides.length > 0) {
        slideItems.splice(1, 0, ...videoSlides);
    }

    return slideItems;
}

// Build out video slides from Vimeo videos on product video attribute
abSlider.getVideoSlides = function(videos, isThumbnail) {
    let videoSlides = [];

    videos.forEach((video, index) => {
        var $slideElement = $('<div></div>');
        if (!isThumbnail) {
            $slideElement.attr('class', 'slide no-zoom');
            $slideElement.html(`<div class="js-video-container video-container">
                                    <div class="video-zoom-modal" data-playerid="player-${video.uuid}-b"></div>
                                    <div id="player-${video.uuid}" class="video-player"
                                        data-asset-id="${video.uuid}"
                                        data-video="${video.type}"
                                        data-player-attr='${video.params}'
                                        data-video-id="${video.id}">
                                    </div>
                                    <div id="player-${video.uuid}-b" class="video-player vid-in-modal aspect-ratio-16-9"
                                        data-asset-id="${video.uuid}"
                                        data-video="${video.type}"
                                        data-player-attr='${video.zoomParams}'
                                        data-video-id="${video.id}">
                                    </div>
                                </div>`);
        } else {
            let $imageElement = $('<img/>');
            let $videoButton = $('<span class="video-thumbnail-button"></span>');
            $slideElement.attr('class', 'slide video-container');
            $imageElement.attr({
                'src': video.thumbnailUrl,
                'alt': video.title,
                'class': 'd-block img-fluid',
                'itemprop': 'image'
            });
            $slideElement.append($imageElement);
            $slideElement.append($videoButton);
        }
        videoSlides.push($slideElement[0]);
    });

    return videoSlides;
}

// Check custom attributes on slider element to get settings, falling back to defaults if not populated
abSlider.getSliderAttributes = function($slider) {
    var attributes = new Object();

    // Autoplay (defaults to false)
    attributes.autoplayDelay = parseInt($slider.attr('data-slider-autoplay'));
    attributes.autoplay = isNaN(attributes.autoplayDelay) ? false : true;
    attributes.autoplayTimeout = attributes.autoplay ? attributes.autoplayDelay : 5000;

    // Loop (defaults to true)
    attributes.loop = $slider.attr('data-slider-loop') === 'false' ? false : true;

    // Number of items to show (defaults to 1 at all breakpoints)
    attributes.itemsNumber = parseInt($slider.attr('data-slider-items'));
    attributes.items = isNaN(attributes.itemsNumber) ? 1 : attributes.itemsNumber;
    attributes.itemsNumberMedium = parseInt($slider.attr('data-slider-items-md'));
    attributes.itemsMedium = isNaN(attributes.itemsNumberMedium) ? 1 : attributes.itemsNumberMedium;
    attributes.itemsNumberLarge = parseInt($slider.attr('data-slider-items-lg'));
    attributes.itemsLarge = isNaN(attributes.itemsNumberLarge) ? 1 : attributes.itemsNumberLarge;

    // LBH Customization - xl falls back to lg b/c it's a new option
    attributes.itemsNumberExtraLarge = parseInt($slider.attr('data-slider-items-xl'));
    attributes.itemsExtraLarge = isNaN(attributes.itemsNumberExtraLarge) ? attributes.itemsLarge : attributes.itemsNumberExtraLarge;

    // Gutter space between items (defaults to 0px)
    attributes.gutter = parseInt($slider.attr('data-slider-gutter')) || 0;
    attributes.gutterMedium = parseInt($slider.attr('data-slider-gutter-md')) || 0;
    attributes.gutterLarge = parseInt($slider.attr('data-slider-gutter-lg')) || 0;
    //LBH Customization - xl falls back to lg
    attributes.gutterExtraLarge = parseInt($slider.attr('data-slider-gutter-xl')) || attributes.gutterLarge;

    // Slide animation speed (defaults to 300ms)
    attributes.speed = parseInt($slider.attr('data-slider-speed')) || 300;

    // Slide animation (defaults to standard Carousel behavior)
    attributes.mode = $slider.attr('data-slider-mode') || 'carousel';
    attributes.animateIn = $slider.attr('data-slider-animatein') || 'no-animation';
    attributes.animateOut = $slider.attr('data-slider-animateout') || 'no-animation';

    // Thumbnail settings (defaults to false)
    // If a selector is set for [data-slider-thumbnail container], the rest of the settings populate automatically
    var $thumbnailSlider = $($slider.attr('data-slider-thumbnail-container'));
    attributes.thumbnailContainer = $slider.attr('data-slider-thumbnail-container') || false;
    attributes.navContainer = $thumbnailSlider.length ? $thumbnailSlider.find('.slider')[0] : false;
    attributes.navAsThumbnails = attributes.navContainer === false ? false : true;
    attributes.controlsContainer = $thumbnailSlider.length ? $thumbnailSlider.find('.slider-thumbnail-controls')[0] : false;

    return attributes;
}

// Build parameter object to create a slider
abSlider.getSliderParams = function($slider, slideItems) {
    var attributes = abSlider.getSliderAttributes($slider);
    var params = {
        container: $slider.find('.slider')[0],
        loop: attributes.loop,
        items: attributes.items,
        autoplay: attributes.autoplay,
        autoplayTimeout: attributes.autoplayTimeout,
        autoplayHoverPause: true,
        mode: attributes.mode,
        gutter: attributes.gutter,
        speed: attributes.speed,
        navContainer: attributes.navContainer,
        navAsThumbnails: attributes.navAsThumbnails,
        controlsContainer: attributes.controlsContainer,
        preventScrollOnTouch: 'auto', // prevents mobile errors in chrome
        responsive: {
            [mediumBreakpoint]: {
                items: attributes.itemsMedium,
                gutter: attributes.gutterMedium,
            },
            [largeBreakpoint]: {
                items: attributes.itemsLarge,
                gutter: attributes.gutterLarge,
            },
            [extraLargeBreakpoint]: {
                items: attributes.itemsExtraLarge,
                gutter: attributes.gutterExtraLarge,
            }
        }
    }

    if (slideItems) {
        params.slideItems = slideItems;
    }

    if (params.mode === 'gallery') {
        params.animateIn = attributes.animateIn;
        params.animateOut = attributes.animateOut;
    }

    return params;
}

// Listen for the slider:update event and reinitialize slider with new slides
abSlider.bindSliderUpdateEvent = function($sliderContainer, slider) {
    $sliderContainer.off('slider:update').on('slider:update', '.slider', (event, data) => {
        var $updatedSliderContainer = $(event.target).closest('.slider-container');
        var isThumbnail = $updatedSliderContainer.hasClass('slider-thumbnails');
        var updatedSlideItems = data !== undefined ? abSlider.getUpdatedSlideItems(data.images, data.assets, data.videos, isThumbnail) : null;
        var updatedParams = abSlider.getSliderParams($updatedSliderContainer, updatedSlideItems);

        // Update carousel classes to handle number of images changing
        if (isThumbnail) {
            var $thumbnailContainer = $updatedSliderContainer.closest('.primary-images-thumbnails');
            var $mainImageContainer = $thumbnailContainer.prev('.primary-images-main');

            if (data.images.small && data.images.small.length > 1) {
                $thumbnailContainer.removeClass('d-none');
                $mainImageContainer.addClass('product-has-thumbnails col-lg-9');
            } else {
                $thumbnailContainer.addClass('d-none');
                $mainImageContainer.removeClass('product-has-thumbnails col-lg-9');
            }
        }

        // Remove and re-add slider markup if there are new slides to show
        if (updatedParams.slideItems) {
            var $newSliderElement = $('<div></div>').attr({
                'class': 'slider',
                'id': updatedParams.container.id
            });
            var $newSliderControls = $updatedSliderContainer.find('.slider-thumbnail-controls').addClass('d-none');
            $newSliderElement.html(updatedParams.slideItems);
            $updatedSliderContainer.html($newSliderElement);
            updatedParams.container = $newSliderElement[0];

            // If this is a thumbnails slider, also add the arrow controls and update the item count
            if (isThumbnail) {
                $updatedSliderContainer.append($newSliderControls);
                updatedParams.items = updatedParams.slideItems.length;
                updatedParams.responsive[mediumBreakpoint].items = updatedParams.slideItems.length;
                updatedParams.responsive[largeBreakpoint].items = updatedParams.slideItems.length;
            }
        }

        // Reinitialize slider using new params
        slider = tinySlider.tns(updatedParams);
        slider.events.on('transitionEnd', abSlider.slideIntoView);
        abSlider.enableImageZoom($updatedSliderContainer);

        if (isThumbnail) {
            abSlider.handleThumbnailArrows($updatedSliderContainer, slider);
        } else {
            // listen for videos getting updated from getUpdatedSlideItems function
            $('body').on('slider:videosLoaded', () => {
                abSlider.handleSliderVideos($updatedSliderContainer, slider);
            });
        }

        // If there are videos in the slider, trigger custom events to reinitialize them
        if ($updatedSliderContainer.find('.js-video-container').length > 0) {
            $('body').trigger('ajax:load.ajaxEvents', [$updatedSliderContainer]); // reinit videos in video/index.js
            $('body').trigger('slider:videosLoaded'); // trigger custom event set up in bindSliderUpdateEvent
        }
    });
}

// Enables zoomed images using jquery-zoom
abSlider.enableImageZoom = function($sliderContainer) {
    if ($sliderContainer.closest('.primary-images').length
        && !$sliderContainer.hasClass('slider-thumbnails')
        && !window.isMobile()) {
        var $slides = $sliderContainer.find('.slide');
        $slides.each((index, slide) => {
            var $slide = $(slide);

            if (!$slide.hasClass('no-zoom')) {
                var zoomImageUrl = $slide.data('zoom-image') || $slide.find('img').attr('src');
                $slide.zoom({
                    url: zoomImageUrl,
                    magnify: 1,
                    touch: false,
                    on: 'click',
                    onZoomIn: function($this) {
                        $slide.parents('.slider-container').addClass('zoomed')
                    },
                    onZoomOut: function($this) {
                        $slide.parents('.slider-container').removeClass('zoomed')
                    }
                });
            }
        });
    }
}

// Determine whether to show thumbnail arrows based on overflow state
abSlider.handleThumbnailArrows = function($sliderContainer, slider) {
    if ($sliderContainer.hasClass('slider-thumbnails')) {
        var $slider = $sliderContainer.find('.slider');
        var $arrows = $sliderContainer.find('.slider-thumbnail-controls');
        var buffer = 5; // adding a buffer to prevent arrows from showing if the last slide is mostly visible
        var containerSize;
        var sliderSize;

        $slider.imagesLoaded().always(() => {
            if (window.isMobile()) {
                containerSize = $sliderContainer.outerWidth();
                sliderSize = $slider[0].scrollWidth;
            } else {
                containerSize = $sliderContainer.outerHeight();
                sliderSize = $slider[0].scrollHeight;
            }

            if (sliderSize - buffer >= containerSize) {
                $arrows.removeClass('d-none');
            }
        });
    }
}

// Check for videos and pause them when they're not in the active slide
abSlider.handleSliderVideos = function($sliderContainer, slider) {
    var $videos = $sliderContainer.find('.hero-media.video-container, .experience-commerce_assets-video>.video-container, .js-video-container');
    var $videoZooms = $sliderContainer.find('.video-zoom-modal')

    if ($videos.length) {
        slider.events.on('indexChanged', () => {
            $videos.each((_i, element) => {
                var $videoContainer = $(element);
                var videoID = $videoContainer.find('.video-player').attr('id');
                var player = window.Players[videoID];

                if (player) {
                    if (!$videoContainer.closest('.tns-slide-active').length) {
                        player.pause();
                    } else if (player.config.autoplay) {
                        player.play();
                    }
                }
            });
        });
    }

    if($videoZooms.length){
        $videoZooms.each((_i, element) => {
            $(element).off().on('click', function() {
                var id = $(element).data('playerid');
                var player = window.Players[id];
                player.domElements.$cntr.addClass('aspect-ratio-16-9');

                $('#videoModal').on('hide.bs.modal', () => {
                    player.pause();
                    player.apiObj.setCurrentTime(0);
                })

                player.launchModal();
            })
        })
    }
}

module.exports = abSlider;
