'use strict';

var recaptcha = require('core/components/recaptcha');
var formValidation = require('base/components/formValidation');
var cart = require('core/cart/cart');
var formErrors = require('core/checkout/formErrors');
var core = require('core/giftCertificate/giftCertificate');
var zonos = require('../zonos');

function giftCertificateFormSubmission() {
    $('body').on('submit', 'form.GiftCertificateForm', function (e) {
        e.preventDefault();

        var form = $(this);
        var isEdit = false;
        var button = form.find('.btn-add-giftcertificate-tocart');
        var url;
        if (!button || !button.length) {
            button = form.find('.btn-update-giftcertificate-incart');
            isEdit = true;
        } else {
            $('body').trigger('product:beforeAddToCart', this);
        }
        form.parents('.card').spinner().start();

        if (isEdit) {
            url = form.attr('action');
        } else {
            url = form.attr('action') + '?' + $('.options-select option:selected').val().split('?')[1];
            if (url.indexOf('&quantity') === -1) {
                url = url + '&quantity=' + $('.product-detail').find('.quantity-select').val();
            }
        }

        var queryString = form.serialize() + '&format=ajax';
        if (isEdit) {
            queryString += '&uuid=' + button.data('uuid');
        }
        button.attr('disabled', true);
        recaptcha.check(e, {
            url: url,
            method: 'POST',
            dataType: 'json',
            data: queryString,
            success: function (data) {
                if (data.success) {
                    if (isEdit) {
                        $('#editGiftCertificateLineItemModal').modal('hide');
                        cart.updateCartTotals(data.cartModel);
                        updateGiftCertificateLineItem(data.customizationLines, data.customizationLabels, button.data('uuid'));
                        $('body').trigger('cart:update', [data, button.data('uuid')]);
                    } else {
                        $('body').trigger('product:afterAddToCart', data);
                        $('#requestGiftCertificatePersonalizationModal').modal('hide');
                    }
                    form.trigger('reset');
                    formErrors.clearPreviousErrors(form);
                    zonos.updateZonosPricing();
                } else {
                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    } else {
                        formValidation(form, data);
                    }
                }

                core.displayMessage(data, button);
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                core.displayMessage(err, button);
            }
        });
    })
};

function updateGiftCertificateLineItem(customizationLines, customizationLabels, uuid) {
    customizationLines.forEach(customizationLine => {
        var customizationArray = customizationLine.split('|');
        var customization = {
            name: customizationArray[0],
            value: customizationArray[1]
        }
        if (customization.name === 'RecipientName') {
            $('.RecipientName-' + uuid + ' span').html(customizationLabels.RecipientName + ': ' + customization.value);
            $('.RecipientName-' + uuid).attr('data-value', customizationLine).data('value', customizationLine);
        } else if (customization.name === 'RecipientEmail') {
            $('.RecipientEmail-' + uuid + ' span').html(customizationLabels.RecipientEmail + ': ' + customization.value);
            $('.RecipientEmail-' + uuid).attr('data-value', customizationLine).data('value', customizationLine);
        } else if (customization.name === 'Message') {
            $('.Message-' + uuid + ' span').html(customizationLabels.Message + ': ' + customization.value);
            $('.Message-' + uuid).attr('data-value', customizationLine).data('value', customizationLine);
        }
    });
}

core.giftCertificateFormSubmission = giftCertificateFormSubmission;
core.updateGiftCertificateLineItem = updateGiftCertificateLineItem;

module.exports = core;
