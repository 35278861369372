'use strict';

var core = require('core/wishlist/components/toggleProduct');

var wishlistHelpers = require('client_core/wishlist/components/helpers');
var toastHelpers = require('core/components/toast');

var toggleProductGuest = function(data) {
    $.ajax({
        url: data.url,
        method: 'POST',
        dataType: 'json',
        data: {
            pid: data.wishlistpid,
            quantity: data.wishlistquantity
        },
        success: function(data) {
            if (!data.error) {
                wishlistHelpers.updateUncachedData({
                    actionType: data.wishlistActionType,
                    pid: data.pid
                });
                wishlistHelpers.updateLinkData(false, data);
                data.guest = true;

                // LBH custom - if on cart page remove product from cart
                var isCart = $('.cart-page').length ? true : false;
                if (isCart) {
                    data.isCart = true;
                    wishlistHelpers.removeWishlistProductFromCart(data);
                }

                wishlistHelpers.openToast(data);
            } else {
                toastHelpers.methods.show('danger', data.message, true);
            }
        }
    });
};

var eventClickHeart = function(event) {
    event.preventDefault();
    var $this = $(event.currentTarget);
    var wishlistpid = event.currentTarget.dataset.wishlistpid;
    if (window.UncachedData.customer.isAuthenticated) {
        $('#wishlistModalShowLists')
                .data('multiSelect', false)
                .data('pid', wishlistpid)
                .data('action', event.currentTarget.dataset.inwishlist === 'true' ? 'remove' : 'add')
                .modal('show', $this);
    } else {
        module.exports.methods.toggleProductGuest(event.currentTarget.dataset);
    }
};

core.methods.toggleProductGuest = toggleProductGuest;
core.methods.eventClickHeart = eventClickHeart;

module.exports = core;
