'use strict';

var glossary = {};

glossary.enableGlossaryPopovers = function () {
    function isTouchDevice() {
        return "ontouchstart" in window || navigator.maxTouchPoints > 0;
    }

    function initializePopovers() {
        var triggerEvents = isTouchDevice() ? "manual" : "hover focus"; // Change trigger to 'manual' for touch devices

        $(".glossary-term").popover({
            html: true,
            trigger: triggerEvents,
            sanitize: false,
            template:
                '<div class="popover glossary-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
        });

        if (isTouchDevice()) {
            $(".glossary-term").on("click touchstart", function (e) {
                e.stopPropagation(); // Stop the event from bubbling up
                $(this).popover("toggle"); // Toggle the popover visibility
            });

            // Close popover when clicking anywhere else in the document
            $(document).on("click touchstart", function (e) {
                $(".glossary-term").each(function () {
                    // Hide any open popovers
                    $(this).popover("hide");
                });
            });

            // Prevent popover from closing when clicking inside
            $(".popover").on("click", function (e) {
                e.stopPropagation(); // Prevent the event from bubbling up to the document
            });
        }
    }

    // Initialize popovers on document ready
    $(document).ready(initializePopovers);

    // Also initialize popovers in response to a custom event
    $(document).on(
        "glossary:update product:afterAttributeSelect product:statusUpdate",
        initializePopovers
    );
};

glossary.requestGlossaryContentOnOpen = function() {
    function requestContentOnOpen(forcedUpdate=false) {
        var $collapsibleContent = $('.js-pdp-description-container');
        var isExpanded = $collapsibleContent.attr('data-expand') === 'true' || $collapsibleContent.find('.card-header button').attr('aria-expanded') === 'true';
        var wasGlossaryLoaded = $('.js-pdp-glossary').data('glossary-loaded') || false;  //Data Attribute to see if we've already gotten the glossary so we don't keep sending requests needlessly
        // Check if the collapsible element is open
        if (isExpanded && (!wasGlossaryLoaded || forcedUpdate)) {
            var content = $('.js-pdp-glossary').data('base-content'); // Get the content from data attribute rather than html(), otherwise we send tooltip markup on subsequent requests
            var $url = $('.js-pdp-glossary').data('action');
            // Make the AJAX request to the SFRA controller action
            $.ajax({
                url: $url,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({ markup: content }),
                success: function(response) {
                    if (response.success && response.updateDOM) {
                        // Update the DOM with the enhanced description
                        $('.js-pdp-glossary').html(response.enhancedDescription);
                        $('.js-pdp-glossary').data('glossary-loaded', true);
                        $('body').trigger('glossary:update'); //trigger to re-initialize popovers after DOM update
                    }
                },
                error: function(xhr, status, error) {
                    console.error('Error:', error);
                }
            });
        }
    }

    // Check initially on document ready
    $(document).ready(requestContentOnOpen);

    // Setup an event listener for when the collapsible's button is clicked
    $(document).on('click', '.js-pdp-description-container .card-header button', function() {
        setTimeout(requestContentOnOpen, 300); // Slight delay for accordian open
    });

    // Reset glossary loaded state on variant change
    $(document).on('product:afterAttributeContentSwap', function() {
        $(document).ready(requestContentOnOpen(true));
    });

};

// Export the glossary object
module.exports = glossary;