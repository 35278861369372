'use strict';

var core = require('core/wishlist/components/modalShowLists');

var wishlistHelpers = require('client_core/wishlist/components/helpers');
var toastHelpers = require('core/components/toast');
var base = require('core/product/base');

var eventShow = function(e) {
    var $this = $(this);
    var action = module.exports.methods.getListAction();
    var dataParams = {};

    $this.data('modalListDataState', {});
    $this.find('.modal-content').spinner().start();
    $('.wishlist-toast').trigger('dismiss');

    if (action === 'move') {
        $('.wishlist-modal-show-lists-add').hide();
        $('.wishlist-modal-show-lists-remove').hide();
        dataParams = {
            listIdOriginal: $this.data().listId,
            listItemIds: JSON.stringify(module.exports.methods.getListItemIds()),
            action: action
        };
    } else if (action === 'remove') {
        $('.wishlist-modal-show-lists-move').hide();
        $('.wishlist-modal-show-lists-add').hide();
        dataParams = {
            pid: module.exports.methods.getPid(),
            action: action
        };
    } else {
        $('.wishlist-modal-show-lists-move').hide();
        $('.wishlist-modal-show-lists-remove').hide();
        dataParams = {
            pid: module.exports.methods.getPid(),
            action: action
        };
    }

    $.ajax({
        url: $this.find('.modal-body').data().url,
        type: 'POST',
        dataType: 'html',
        data: dataParams,
        success: function(html) {
            module.exports.methods.updateWishlistProducts($(html));
            module.exports.methods.updateModalListDataState();
            $this.find('.modal-content').spinner().stop();
        }
    });
    module.exports.methods.addListEventListenersWindow();
};

var eventToggleProductOnWishlist = function(e) {
    e.preventDefault();
    var $listButton = $(this);
    var $productContainer = $('.product-detail[data-pid='+ $listButton.data().pid +']');
    var options = base.methods.getOptions($productContainer);
    var isCart = $('.cart-page').length ? true : false;

    options ? $listButton.attr('data-product-options', JSON.stringify(options)) : '';
    $listButton.closest('.modal-content').spinner().start();

    $.ajax({
        url: $listButton.data().url,
        method: 'POST',
        dataType: 'json',
        data: {
            pid: module.exports.methods.getPid(),
            wishlistId: $listButton.data().wishlistId,
            quantity: module.exports.methods.getQuantity(),
            options: options
        },
        success: function(data) {
            var $html = $(data.renderedTemplate);
            if (!data.error && $html.length) {
                var $modal = $('#wishlistModalShowLists');
                var productListSelectedCount = $html.find('.selected').length;
                module.exports.methods.updateWishlistProducts($html);
                module.exports.methods.updateModalListDataState();
                wishlistHelpers.updateUncachedData({
                    actionType: productListSelectedCount ? 'add' : 'remove',
                    pid: module.exports.methods.getPid()
                });
                wishlistHelpers.updateLinkData();

                if (!$modal.data().multiSelect) {
                    $modal
                        .one('hidden.bs.modal', function () {
                            var buttonData = $listButton.data();
                            buttonData.isCart = isCart;
                            wishlistHelpers.openToast(buttonData);
                        })
                        .modal('hide');
                }
            } else {
                toastHelpers.methods.show('danger', data.message, true);
            }

            $listButton.closest('.modal-content').spinner().stop();
        },
        error: function() {
            $listButton.closest('.modal-content').spinner().stop();
        }
    }).then(() => {
        // LBH custom - if on cart page remove product from cart
        if (isCart) {
            wishlistHelpers.removeWishlistProductFromCart($listButton.data());
        }
    });
};

core.methods.eventShow = eventShow;
core.methods.eventToggleProductOnWishlist = eventToggleProductOnWishlist;

module.exports = core;
