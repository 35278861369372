'use strict';

function hpCategoryGrid() {
    $(document).on('mouseenter', '.js-btn-category-grid', function() {
        var $container = $(this).closest('.rs-style-rollover');
        var $sliderElement = $container.find('.js-category-grid-slider');
        if ($sliderElement.length > 0) {
            var slider = $sliderElement.data('tns');
            if (slider) {
                // Extract the index from the class name
                var hoverClass = $(this).attr('class').split(' ').find(c => c.startsWith('js-grid-index-'));
                if (hoverClass) {
                    var index = parseInt(hoverClass.split('-')[3]);
                    if (!isNaN(index)) {
                        slider.goTo(index);
                    }
                } 
            } 
        } 
    });
}


module.exports = hpCategoryGrid;