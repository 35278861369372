
function zonosInit() {
    if (!window.zonos) {
        return;
    }

    window.zonos.config({
        currencySelectors: '.z-price'
    });
}

export function updateZonosPricing() {
    zonosInit();
    if (!window.zonos) {
        return;
    }
    window.zonos.displayCurrency();
}

window._zonosInit = zonosInit;
