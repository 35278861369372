'use strict';

var core = require('core/components/search');

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = module.exports.getSuggestionsWrapper(this).empty();
    var $navigationMenu = $('.main-menu.menu-toggleable-left');
    $.spinner().stop();

    if (typeof (response) !== 'object') {
        $suggestionsWrapper.append(response).show();
        $(this).siblings('.reset-button').addClass('d-sm-block');
        module.exports.positionSuggestions(this);

        if (module.exports.isMobileSearch(this)) {
            module.exports.toggleSuggestionsIcon('close');
            module.exports.applyModals(this);
        }

        if ($navigationMenu.hasClass('in')) {
            $navigationMenu.removeClass('in');
            $navigationMenu.attr('aria-hidden', 'true');
            $('body').removeClass('mobile-menu-in');
            $('html').removeClass('lock-scroll');
            $('.modal-background').hide();
        }

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.suggestions .item');
        if ($(suggestionsList).length) {
            $('input.search-field').attr('aria-describedby', 'search-result-count');
        } else {
            $('input.search-field').removeAttr('aria-describedby');
        }
    } else {
        $suggestionsWrapper.hide();
    }
}

core.processResponse = processResponse;

module.exports = core;
